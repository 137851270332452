.btn-width-40px {
  width: 150px;
  background: red;
}

.search-label {
  margin: 7px;
}

.button-actions {
  display: flex;
  align-content: center;
  flex-direction: row;
  justify-content: center;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.button-actions button {
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  padding: 5px;
}

.btn-equal-size {
  width: 140px; /* El valor según el ancho deseado para todos los botones en la lista de los clientes */
}
