/* navbar.css */

/* Dropdown Menu (cuando está visible) */
.dropdown-menu.show {
    display: block;
    text-align: center;
    width: 210px; /* Ajuste del ancho del dropdown */
    position: absolute; /* Asegura que el dropdown no interfiera con otros elementos */
    top: 50px; /* Alinea el dropdown debajo de la campana de notificaciones */
    right: 0; /* Alinea el menú a la derecha */
    z-index: 1000; /* Asegura que el menú esté encima de otros elementos */
}

/* Alineación derecha para el dropdown */
.dropdown-menu-right {
    position: absolute;
    top: 50px;
    right: 0;
}

/* Estilos generales de la barra de navegación */
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    /* background-color: #12675c; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra para una mayor definición */
}

.dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; /* Asegura que el cursor cambie al hacer hover */
}

/* Estilo del avatar */
.nav-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 8px;
}

.bell-center {
    margin: auto;
}

/* Estilo del nombre de usuario */
.username {
    color: white; /* Asegura que el texto sea visible */
    font-size: 16px;
    margin: 0; /* Elimina márgenes */
}

/* Estilos para pantallas de hasta 1366px */
@media (max-width: 1366px) {
    .dropdown-toggle {
        flex-direction: row;
        justify-content: center;
    }

    .nav-avatar {
        width: 35px;
        height: 35px;
    }

    .username {
        font-size: 15px;
        text-align: center;
    }

    .navbar {
        padding: 8px;
    }
}

/* Estilos para pantallas entre 1024px y 1366px */
@media (max-width: 1024px) {
    .navbar {
        flex-direction: column;
        align-items: center;
    }

    .logo, .imag {
        max-width: 30%;
        height: auto;
        margin: auto;
    }

    .notification-bell {
        margin-left: 0;
        justify-content: center;
    }

    .dropdown-menu {
        min-width: 200px;
    }
}

/* Estilos para la campana de notificaciones */
.notification-bell {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notification-count {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 0 5px;
    font-size: 12px;
    font-weight: bold;
} 


/* Estilos de los elementos dentro del dropdown */
.notification-dropdown .dropdown-item {
    padding: 10px;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    border-bottom: 1px solid #f0f0f0;
    transition: background-color 0.3s;
}

.notification-dropdown .dropdown-item:hover {
    background-color: #12675c;
}

.notification-dropdown .dropdown-item:active {
    background-color: #e2e2e2;
}

/* Estilos adicionales para los elementos deshabilitados */
.notification-dropdown .dropdown-item.disabled {
    color: #999;
    cursor: not-allowed;
}

  /* Evitar que el dropdown se desplace fuera de la pantalla */
  .notification-dropdown {
    position: 'absolute';
    top: '100%';
    left: '50%';
    transform: 'translateX(-50%)';
    z-index: 1000;
    background-color: 'white';
    border: '1px solid #ccc';
    border-radius: '5px';
    width: '380px';
    margin-top: '5px';
  }
