@import 'react-big-calendar/lib/css/react-big-calendar.css';
  
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.color-green {
  color: white;
  background-color: #173d38;
}

.color-red {
  color: white;
  background-color: #e65b31;
}

.color-green-info {
  color: white;
  background-color: #173d38;
  border-radius: 50%;
  cursor: pointer;
}

.page-link {
  color: #173d38;
}

.b-gray {
  background-color: #bababa29;
}

a{
  cursor: pointer;
}

.active{
  color: #173d38;
}