.footer-buttons {
    text-align: end;
}

.button-cancel {
    margin: 5px;
}

.button-save {
    margin: 5px;
}

.box-status-list {
    display: flex;
}

.status-list {
    margin-right: 50%;
}

.dialog{
    text-align: center;
}

.id_sale {
    color:red;
    font-weight: bold;
}

.id_sale:hover {
    color:#173d38;
}

.div_card{
    width: 18rem;
}

.card_content {
    display: contents;
}

.card_body {
    padding: 5px;
}

