.footer-buttons {
    text-align: end;
}

.button-cancel {
    margin: 5px;
}

.button-save {
    margin: 5px;
}

.transparent-button {
    background-color: transparent; 
    border: none; 
    padding: 0; 
    cursor: pointer;
    font-size: 50px;
}
