.img {
    width: 85%;
  }
  
  .logo {
    width: 40%;
    display: block;
    margin: auto;
  }
  
  .image {
    width: 40%;
    margin-left: auto;
  }
  .imag {
    width: 50%;
    display: block;
    margin: auto;
  }
  
  .required {
    color: red;
  }
  
  .navbar {
    background: #173d38;
  }
  
  .google {
    margin: auto;
  }
  
  .nav-avatar {
    background-size: cover;
    border: solid 5px #1e645b;
    border-radius: 50%;
    height: 50px;
    vertical-align: middle;
    width: 50px;
  }
  
  .dropdown-toggle::after {
    display: none !important;
  }
  
  .username {
    color: white;
    margin-right: 10px;
    margin-top: auto;
    /* font-style: oblique; */
  }
  
  #dropdown-split-basic {
    display: flex;
  }
  
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .spinner-container {
      display: inline-flex;
  }
  
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }

  .loading-spinner-secondary {
    width: 10px;
    height: 10px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }
  
  .pagination a  {
    background-color: #173d38;
    border-color: #848e8d;
    color: #f3f3f3;
  }

  .pagination a:hover  {
    background-color: #011815 !important;
    color: #f3f3f3;
  }

  .pagination .active  {
    background-color: #011815 !important;
    border-color: #848e8d;
  }

  .page-link.active {
    border-color: #848e8d !important;
  }

  .mi-modal .modal-dialog {
    width: 75%;
    max-width: 75%;
  }




  

  /* PagoExitoso.css */

.payment-successful {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  background-color: #f8f8f8;
}

.payment-successful-container {
  text-align: center;
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.icon {
  width: 120px;
  height: 100px;
  margin-bottom: 20px;
}

.headline {
  font-size: 32px;
  color: #333;
  margin-bottom: 20px;
}

.description {
  font-size: 18px;
  color: #666;
  margin-bottom: 10px;
}

.gratitude {
  font-size: 24px;
  color: #333;
  font-weight: bold;
}

.container-dashboard {
  padding: 1%;
  width: 80%;
  margin: 0 auto;
}